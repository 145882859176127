import React from "react"

import Layout from "../components/layout"
import ProductsMainArea from "../components/ProductsMainArea"
import ProductsPage from "../components/ProductsPage"
import { graphql } from "gatsby"
import Arrow from "../components/Arrow"

const Products = ({
  data: {
    markdownRemark: {
      frontmatter: { title, description, lang },
    },
    allMarkdownRemark: { edges },
  },
}) => {
  const products = edges.map(({ node }) => node.frontmatter)
  return (
    <Layout title={title} lang={lang}>
      <ProductsMainArea title={title} description={description} />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <ProductsPage products={products} />
        </div>
      </div>
    </Layout>
  )
}
export default Products

export const query = graphql`
  query($slug: String!, $lang: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        lang
        description
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(content/products)/.*\\\\.md$/" }
        frontmatter: { lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            name
            path
            description
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
