import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./style.css"
import useLang from "../../hooks/useLang"

export default function ProductsPage({ products }) {
  const isAr = useLang() === "ar"
  const moreText = isAr ? "المزيد" : "More"
  return (
    <div className="products-page">
      <div className="container">
        <div className="row">
          <div className="productGrid">
            {products.map(({ title, name, path, description, images }) => (
              <div className="row mb-5">
                <div className="col-md-7">
                  <div className="pdThumb">
                    <Img
                      fluid={images[0].image?.childImageSharp.fluid}
                      alt={name}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="pdDetails">
                    <span className="ml-4">{title}</span>
                    <h2 className="ml-4">{name}</h2>
                    <p className="ml-4">{description} </p>
                    <Link to={"/" + path} className="ml-4 BtnAction">
                      {moreText}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
