import React from "react"
import "./style.css"
import MainAreaSection from "../MainAreaSection"
import useLang from "../../hooks/useLang"

const ProductsMainArea = ({ description }) => {
  const productsTitle = useLang() === "ar" ? "منتجاتنا" : "Our Products"
  return (
    <MainAreaSection
      img={require("../../images/page-layer-bg1.jpg")}
      title={productsTitle}
      currentPage={productsTitle}
    >
      <div className="heading-text">
        <p>{description}</p>
      </div>
    </MainAreaSection>
  )
}
export default ProductsMainArea

ProductsMainArea.defaultProps = {
  description: "Take a look at few of the Featured products we have worked on.",
}
