import React from "react"
import { Link } from "gatsby"
import "./style.css"
import useLang from "../../hooks/useLang"
import { Box } from "theme-ui"

export default function MainAreaSection({ title, currentPage, img, children }) {
  const language = useLang()
  const isAr = language === "ar"
  return (
    <div className="about">
      <div className="b-page-content with-layer-bg">
        <div className="b-layer">
          <Box
            sx={{
              mt: [0, null, null, 100],
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
            }}
            className="layer-bg page-layer-bg5"
          >
            <div className="layer-content">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 text-center">
                    <div className="b-breadcrumbs">
                      <ul className="list-inline">
                        <li>
                          <Link to={isAr ? "/ar/" : "/"}>
                            {isAr ? "الرئيسية" : "home"}
                          </Link>
                        </li>
                        <li className="current">{currentPage}</li>
                      </ul>
                    </div>
                    <h1 className="main-heading">{title}</h1>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  )
}
